<template>
    <section class="user-list section-gap-30">
        <div class="section-title-wrap">
            <h2 class="section-title">{{ $t("recent applicants") }}</h2>
        </div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-5" v-if="isContentLoading">
            <div class="col" v-for="count in placeholderCounts">
                <CandidateCardLoader></CandidateCardLoader>
            </div>
        </div>
        <div class="recent-applicants-sections" v-else>
            <h4 class="empty-message" v-if="isEmpty">{{ $t("No applicants found") }}</h4>
            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-5" v-if="!isEmpty">
                <div class="col" v-for="applicant in applicants">
                    <candidate-card :applicant="applicant"></candidate-card>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
const CandidateCard = () =>  import("../candidate/CandidateCard");
const CandidateCardLoader = () =>  import("../_loaders/CandidateCardLoader");

import client from '../../app/api/Client';
import {SWITCHED_TO_ANOTHER_ACCOUNT} from '../../constants/events';
import {EventBus} from '../../extra/event-bus';

export default {
    components: {
        CandidateCard,
        CandidateCardLoader
    },
    data() {
        return {
            isContentLoading: false,
            placeholderCounts: [1, 2, 3, 4, 5],
            applicants: []
        }
    },

    computed: {
        isEmpty() {
            return this.applicants.length === 0;
        }
    },

    methods: {
        async loadRecentApplicants() {
            this.isContentLoading = true;
            try {
                let {data: {data}} = await client().get('/company/recent-applicants');
                this.applicants = data;
            } catch (e) {
            }
            this.isContentLoading = false;
        }
    },

    mounted() {
        EventBus.$on(SWITCHED_TO_ANOTHER_ACCOUNT, (c) => {
            if (c === 'company') {
                this.loadRecentApplicants();
            }
        });

    },
    created() {
        this.loadRecentApplicants();
    }
}
</script>

